// Background color opacity
@mixin background-opacity($color: #383836, $opacity: 0.85) {
    background: $color;
    background: rgba($color, $opacity);
}

// Box rgba
@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #383836) {
    background-color: rgba($r, $g, $b, $opacity);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}', endColorstr='#{$color}');
    zoom: 1;
}

// Columns
@mixin columns($count: 3, $gap: 10) {
    @include css3-prefix('column-count', $count);
    @include css3-prefix('column-gap', $gap);
}

// Double borders
@mixin double-borders($colorOne: #383836, $colorTwo: #577a9b, $radius: 0) {
    border: 1px solid $colorOne;
    @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo) {
        @include border-radius($radius);
    }
}

// Flex
@mixin flex($value: 1) {
    @include css3-prefix('box-flex', $value);
}

// Flip
@mixin flip($scaleX: -1) {
    @include css3-prefix('transform', scaleX($scaleX));
    filter: 'FlipH';
}

// Outline radius
@mixin outline-radius($radius: 5px) {
    @include css3-prefix('outline-radius', $radius);
}

// Text shadow
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4)) {
    text-shadow: $x $y $blur $color;
}


/* Transform style */

@mixin transform-style($style: preserve-3d) {
    @include css3-prefix('transform-style', $style);
}

// Triple borders
@mixin triple-borders($colorOne: #383836, $colorTwo: #577a9b, $colorThree: #5f605e, $radius: 0) {
    border: 1px solid $colorOne;
    @include border-radius($radius);
    @include css3-prefix('box-shadow', 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree);
}

// Animation
@mixin animation($str) {
    @include css3-prefix('animation', $str);
}

// Vertically align any element
@mixin vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

// Box shadow
@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4), $inset: "") {
    @if ($inset !="") {
        @include css3-prefix('box-shadow', $inset $x $y $blur $color);
    }
    @else {
        @include css3-prefix('box-shadow', $x $y $blur $color);
    }
}

// Rounded image
@mixin round-img {
    position: relative;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    border-radius: 50%;
}

// Loaders
@mixin image-loader($type) {
    $loaderPath: '';
    // Check for loader type
    @if ($type=='large') {
        $loaderPath: $imagesPath + "spinner.gif";
    }
    @else if ($type=='small') {
        $loaderPath: 'We need some small loader here';
    }
    // Check do we have a loader path
    @if ($loaderPath !='') {
        background: url($imagesPath + $loaderPath) no-repeat center;
        >* {
            visibility: hidden;
        }
    }
}

@function to-rgba($color, $alpha: null) {
    $red: round(red($color));
    $green: round(green($color));
    $blue: round(blue($color));
    $alpha: if($alpha, $alpha, alpha($color));

    @return unquote('rgba(#{$red}, #{$green}, #{$blue}, #{$alpha})');
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @-moz-keyframes #{$name} {
        @content;
    }
    @-o-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

%transitionTopBlue {
    transform: perspective(1px) translateZ(0);
    &:hover:before {
        transform: translateY(0);
    }
    &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        background: #2098D1;
        height: 4px;
        transform: translateY(-4px);
        transition-property: transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-out;
    }
}

%textOverflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

%addEventButton {
    .add-events--button {
        position: fixed;
        right: 3em;
        bottom: 3em;
        z-index: 1;
        button {
            object-fit: contain;
            background-color: #323c46 !important;
            box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.15) !important;
        }
        @media screen and(max-width: 768px) {
            right: 0;
        }
    }
}

%editEventButton {
    .edit-events--button {
        position: fixed;
        right: 3em;
        bottom: 3em;
        z-index: 1;
        button {
            object-fit: contain;
            background-color: rgb(34, 212, 134) !important;
            box-shadow: 0 6px 9px 0 rgba(0, 0, 0, 0.15) !important;
        }
        @media screen and(max-width: 768px) {
            right: 0;
        }
    }
}


%spanTitle {
    font-weight: normal;
    color: $grey;
    text-transform: uppercase;
    font-size: 0.75rem;
}

%badge {
    font-family: $CircularStd-Bold;
    font-size: 0.75rem;
    color: $white;
    background-color: $green;
    padding: 0.3rem 0.85rem;
    border-radius: 4px;
    text-transform: uppercase;
}

%status-badge{
    font-family: $CircularStd-Medium;
    font-weight: 500;
    width: 130px;
    font-size: 11px;
    color: $white;
    border: none;
    padding: 0.5rem 1.06rem;
    border-radius: 4px;
    text-transform: uppercase;
}

%session-wrapper{
    width: 100%;
    padding: 45px 30px !important;
}

%session-info-inner {
    background-color: $white;
    border: solid 1px $lightOffWhite;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
}

%session-title{
    font-family: $CircularStd-Bold;
    margin-right: auto;
    font-size: 1.875rem;
    color: $darkBlue;
}

%session-info-title {
    padding: 1.56rem 1.88rem;
    align-items: center;
    border-bottom: solid 1px $lightOffWhite;
    display: flex;
}

%session-info-title-h2 {
    margin-right: auto;
    font-family: $CircularStd-Bold;
    color: $darkGrey2;
    font-size: 1.2rem;
}

%session-info-flex {
    padding: 1.56rem 1.88rem;
    display: flex;
    align-items: center;
}

%form-label {
    display: inline-block;
    font-family: $CircularStd-Bold;
    font-size: 0.75rem;
    color: $grey;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
}

%form-input {
    padding: 9px 16px;
    border-radius: 4px;
    border: solid 1px #d8dce6;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    font-family: $CircularStd-Book;
}

%form-input-error {
    border: solid 0.9px #ff5c6f;
    background-color: rgba(255, 92, 111, 0.1);
}

%error-message {
    text-transform: initial;
    font: 12px 500 $CircularStd-Book;
    font-style: italic;
    color: #ff5c6f;
}

%btn{
    font-family: $CircularStd-Book;
    font-size: 13.33px!important;
    color: $white;
    padding: 10px 1.06rem!important;
    height: 2.38rem;
    border-radius: 4px;
    cursor: pointer;
}

%btn-green {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: $white;
    font: 14px $CircularStd-Medium;
    border: solid 1px #2d9c3c;
    border-radius: 4px;
    background-image: linear-gradient(to top, #34aa44, #38b249);
    box-shadow: 0 1px 1px 0 rgba(19, 31, 21, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    cursor: pointer;
    &:disabled {
        opacity: 0.6;
        cursor: initial;
    }
}

%btn-blue {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: $white;
    font: 14px $CircularStd-Medium;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    border: solid 1px #1b8cd2;
    background-color: #3cb0f7;
    border-radius: 4px;
    cursor: pointer;
    &:disabled {
        opacity: 0.6;
        cursor: initial;
    }
}

%btn-red {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: $white;
    font: 14px $CircularStd-Medium;
    box-shadow: 0 1px 1px 0 rgba(22, 29, 37, 0.1),
    inset 0 2px 0 0 rgba(255, 255, 255, 0.06);
    border: solid 1px #d4391d;
    background-color: #e6492d;
    border-radius: 4px;
    cursor: pointer;
    &:disabled {
        opacity: 0.6;
        cursor: initial;
    }
}

%outer-link {
    text-align: center;
    font: 14px $CircularStd-Medium;
    line-height: 1.57;
    color: $grey;
}

%modal-container{
    position: absolute;
    max-width: 370px;
    width: 370px;
    background-color: $white;
    border-radius: 4px;
}

%modal-title-wrapper{
    display: flex;
    padding: 0 30px;
    align-items: center;
    border-bottom: 1px solid $lightOffWhite;
}

%modal-title{
    color: $darkBlue;
    font-family: $CircularStd-Bold;
    margin-right: auto;
    font-size: 20px;
    padding: 20px 0;
}

%modal-overlay{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: to-rgba($darkBlue, 0.9);
    z-index: 9999;
}

%table{
    width: 100%;
    background-color: $white;
    border: solid 1px $lightOffWhite;
    border-top: none;
    position: relative;
    border-collapse: separate;
    border-spacing: 0;
}

%table-header{
    border-top: solid 1px $lightOffWhite;
    border-bottom: solid 1px $lightOffWhite;
    font-family: $CircularStd-Medium;
    text-align: left;
    padding: 0.75rem 1.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $grey;
}

%table-cell{
    font-family: $CircularStd-Book;
    font-size: 0.94rem;
    color: $darkGrey;
    padding: 1.3rem 0;
    border-bottom: solid 1px $lightOffWhite;
}

%codeBlock{
    color: $darkBlue;
    font-size: 11px;
    font-family: $B612Mono-Regular;
    word-wrap: break-word;
}

%notification-warning{
    display: flex;
    align-items: center;
    background-color: $warning;
    margin-top: 30px;
    padding: 13px 20px;
    font-size: 14px;
    font-family: $CircularStd-Medium;
    border-radius: 4px;
    .warning-message{
        margin-right: auto;
        margin-left: 1rem;
    }
    .warning-message, p{
        font-size: 14px;
        color: $white;
    }
    .close-x {
        cursor: pointer;
        width: 12px;
        height: 12px;
        color: $white;
        font-family: sans-serif;
        transform: rotate(-315deg) scale(1.6);
    }
}